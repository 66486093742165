<template>
  <div>
    <h4>
      <strong>Competencias Transversales</strong>
    </h4>
    <div
      v-can="'mesh.add_institutioncompetence mesh.change_institutioncompetence'"
      style="float: right"
    >
      <b-form-checkbox v-model="allows_crud" switch
        >Editar Formulario</b-form-checkbox
      >
    </div>
    <div v-can="'mesh.add_institutioncompetence'" style="float: left">
      <b-button
        v-if="allows_crud"
        class="btnCreate mb-1"
        size="sm"
        @click="$bvModal.show('modal-create-competence')"
        >Crear Competencia</b-button
      >
    </div>
    <div class="mt-1">
      <div class="row selected-test-header py-1 mb-1">
        <div class="col" style="text-align: left">
          <b-avatar
            v-if="selectedCompetence"
            v-bind:style="{ backgroundColor: selectedCompetence.color }"
            :text="selectedCompetence.short_name"
            style="border: 2px solid white"
          ></b-avatar>
        </div>
        <div
          class="col-8 selected-test-header"
          v-b-toggle.collapse-a.collapse-institution-competences
        >
          <template v-if="selectedCompetence">
            <div class="my-0" style="cursor: pointer">
              <h3 class="my-0">{{ selectedCompetence.name }}</h3>
              <p style="font-size: var(--secondary-font-size)" class="my-0">
                {{ selectedCompetence.action }}
                {{ selectedCompetence.content }}
                {{ selectedCompetence.condition }}
              </p>
            </div>
          </template>
        </div>
        <div class="col" style="text-align: right">
          <b-button
            v-b-toggle.collapse-a.collapse-institution-competences
            size="sm"
            class="btnArrows"
            variant="none"
          >
            <b-icon-chevron-double-down
              v-if="!collapse_visible"
              class="icon"
            ></b-icon-chevron-double-down>
            <b-icon-chevron-double-up
              v-else
              class="icon"
            ></b-icon-chevron-double-up>
          </b-button>
        </div>
      </div>
      <b-collapse
        v-model="collapse_visible"
        id="collapse-institution-competences"
        class="mt-1"
        visible
      >
        <ul>
          <template v-for="institution_competence in institutionCompetences">
            <b-media
              tag="li"
              :key="institution_competence.id"
              class="test-row"
              v-bind:class="[
                institution_competence.id == selected_competence_id
                  ? 'selected-test'
                  : '',
              ]"
            >
              <template v-slot:aside>
                <div>
                  <b-avatar
                    class="ml-2 mt-2"
                    v-bind:style="{
                      backgroundColor: institution_competence.color,
                    }"
                    :text="institution_competence.short_name"
                  ></b-avatar>
                </div>
              </template>
              <div class="row mx-0 py-2">
                <div
                  class="col"
                  @click="selectCompetence(institution_competence.id)"
                  style="cursor: pointer"
                >
                  <h4 class="mt-0 mb-0">{{ institution_competence.name }}</h4>
                  <p class="mb-0">
                    {{ institution_competence.action }}
                    {{ institution_competence.content }}
                    {{ institution_competence.condition }}
                  </p>
                </div>
                <div
                  v-can="'mesh.change_institutioncompetence'"
                  class="col"
                  style="max-width: 50px"
                >
                  <template v-if="allows_crud">
                    <button-edit
                      @click="
                        $bvModal.show(
                          `modal-update-competence-${institution_competence.id}`
                        )
                      "
                    ></button-edit>
                    <b-modal
                      :id="`modal-update-competence-${institution_competence.id}`"
                      title="Modificar Competencia Genérica"
                      hide-footer
                    >
                      <InstitutionCompetenceForm
                        :InstitutionCompetence="institution_competence"
                        :show_title="false"
                        :show_delete_button="true"
                        @updated="
                          $bvModal.hide(
                            `modal-update-competence-${institution_competence.id}`
                          )
                        "
                        @deleted="
                          $bvModal.hide(
                            `modal-update-competence-${institution_competence.id}`
                          )
                        "
                      ></InstitutionCompetenceForm>
                    </b-modal>
                  </template>
                </div>
              </div>
            </b-media>
          </template>
        </ul>
      </b-collapse>
      <template v-for="competence in institutionCompetences">
        <div
          v-if="competence.id == selected_competence_id"
          :key="competence.id"
        >
          <PerformanceIndicators
            :InstitutionCompetence="competence"
            :allows_crud="allows_crud"
          ></PerformanceIndicators>
        </div>
      </template>
    </div>

    <b-modal
      id="modal-create-competence"
      title="Crear Competencia Genérica"
      hide-footer
    >
      <InstitutionCompetenceForm
        :show_title="false"
        @created="$bvModal.hide('modal-create-competence')"
      ></InstitutionCompetenceForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "InstitutionCompetencesView",
  components: {
    PerformanceIndicators: () => import("./PerformanceIndicators"),
    InstitutionCompetenceForm: () => import("./InstitutionCompetenceForm"),
  },
  data() {
    return {
      selected_competence_id: null,
      allows_crud: false,
      collapse_visible: true,
    };
  },
  computed: {
    ...mapGetters({
      institutionCompetences: names.INSTITUTION_COMPETENCES,
    }),
    selectedCompetence() {
      return this.institutionCompetences.find(
        (x) => x.id == this.selected_competence_id
      );
    },
  },
  methods: {
    selectCompetence(competence_id) {
      this.selected_competence_id = competence_id;
      this.collapse_visible = false;
    },
  },
};
</script>

<style scoped>
.selected-test-header {
  background-color: var(--primary-color);
  color: white;
  width: 100%;
  margin-left: 0;
}
.selected-test {
  background-color: #c5c5c5;
  border-radius: 5px;
}
.test-row:hover {
  background-color: #b1adad;
  border-radius: 5px;
}

ul {
  padding-left: 0.2rem;
}
</style>